enum EnumStore {
	GEOCODING = 'GEOCODING',
	USER = 'USER',
	INFORMATION = 'INFORMATION',
	CATEGORY_OBJECT = 'CATEGORY_OBJECT',
	PLACES = 'PLACES',
	NOTIFICATIONS = 'NOTIFICATIONS',
	CLIENT = 'CLIENT',
	SERVICE = 'SERVICE',
	EVENTS = 'EVENTS',
	AUDIO_GUIDE = 'AUDIO_GUIDE',
	MUSEUM_GUIDE = 'MUSEUM_GUIDE',
}

export default EnumStore;
